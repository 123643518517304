import * as React from "react";
import Modal from "react-modal";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

// react-modalの初期設定
// root nodeのSelectorを設定
Modal.setAppElement("#___gatsby");

export default class ContactPage extends React.Component {
	state = {
		r: "",
		seiKanji: "",
		meiKanji: "",
		seiKana: "",
		meiKana: "",
		email: "",
		tel: "",
		companyname: "",
		message: "",
		sendFlag: false,
		modalIsOpen: false,
	};

	handleCloseModal = () => {
		this.setState({
			modalIsOpen: false,
		});
	};

	handleInputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({
			modalIsOpen: true,
		});
	};

	sendMail = () => {
		this.setState({
			modalIsOpen: false,
		});
		fetch("https://us-central1-greenstudio-homepage.cloudfunctions.net/sendMail", {
			method: "POST",
			body: JSON.stringify(this.state),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				this.setState({
					sendFlag: true,
				});
			})
			.catch((error) => {
				this.setState({
					sendFlag: true,
				});
			});
		return;
	};

	render() {
		const siteTitle = this.props.data.site.siteMetadata?.title || `Title`;

		let contactForm;

		if (this.state.sendFlag) {
			contactForm = (
				<div>
					<div className="contact-result-title">お問い合わせ完了</div>
					<div className="contact-result-discription">
						このたびはお問い合わせをいただき誠にありがとうございました。
						<br />
						お問い合わせいただきました内容を確認の上、担当者よりご連絡をさせていただきます。
						<br />
						なお、しばらくたっても返信がない場合には、お客様にご入力いただきましたご連絡先に誤りがある場合がございます。
						<br />
						その場合には、大変お手数ですが、再度お問い合わせの手続きをお願い致します。
					</div>
				</div>
			);
		} else {
			contactForm = (
				<div>
					<h2 className="contact-h2">C o n t a c t</h2>
					<form className="contact-contents-block" id="mailForm" onSubmit={this.handleSubmit}>
						<span className="contact-contents-block-title">お問い合わせの種類 ※</span>
						<span className="contact-contents-block-text contact-contents-block-radio">
							<label>
								<input className="contact-form-radio" type="radio" id="r" name="r" onChange={this.handleInputChange} value="業務のご相談" required />
								業務のご相談
							</label>
							<label>
								<input className="contact-form-radio" type="radio" id="r" name="r" onChange={this.handleInputChange} value="当社に対するお問い合わせ" />
								当社広報活動に対するお問い合わせ
							</label>
							<label>
								<input className="contact-form-radio" type="radio" id="r" name="r" onChange={this.handleInputChange} value="採用・人事について" />
								採用・人事について
							</label>
							<label>
								<input className="contact-form-radio" type="radio" id="r" name="r" onChange={this.handleInputChange} value="その他" />
								その他
							</label>
						</span>
						<span className="contact-contents-block-title">お名前 ※</span>
						<span className="contact-contents-block-text">
							<input className="contact-form-name" type="text" name="seiKanji" value={this.state.seiKanji} onChange={this.handleInputChange} placeholder="姓" required />
							<input className="contact-form-name" type="text" name="meiKanji" value={this.state.meiKanji} onChange={this.handleInputChange} placeholder="名" required />
						</span>
						<span className="contact-contents-block-title">ふりがな ※</span>
						<span className="contact-contents-block-text">
							<input className="contact-form-name" type="text" name="seiKana" value={this.state.seiKana} onChange={this.handleInputChange} placeholder="せい" required />
							<input className="contact-form-name" type="text" name="meiKana" value={this.state.meiKana} onChange={this.handleInputChange} placeholder="めい" required />
						</span>
						<span className="contact-contents-block-title">メールアドレス ※</span>
						<span className="contact-contents-block-text">
							<input className="contact-form-text" type="email" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder="abc@example.com" required />
						</span>
						<span className="contact-contents-block-title">電話番号 ※</span>
						<span className="contact-contents-block-text">
							<input className="contact-form-text" type="tel" name="tel" value={this.state.tel} onChange={this.handleInputChange} placeholder="000-000-0000" required />
						</span>
						<span className="contact-contents-block-title">貴社名</span>
						<span className="contact-contents-block-text">
							<input className="contact-form-text" type="text" name="companyname" value={this.state.companyname} onChange={this.handleInputChange} />
						</span>
						<span className="contact-contents-block-title">内容 ※</span>
						<span className="contact-contents-block-text">
							<textarea className="contact-form-text" name="message" value={this.state.message} onChange={this.handleInputChange} required />
						</span>
						<span className="contact-contents-block-title"></span>
						<span className="contact-contents-block-text">
							<button type="submit">入力内容を送信する</button>
						</span>
					</form>
				</div>
			);
		}

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<Seo title="Contact" />
				<hr />
				{contactForm}
				<Modal
					className="modal"
					overlayClassName="modal-overlay"
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.handleCloseModal}
					shouldCloseOnEsc={true}
					shouldCloseOnOverlayClick={true}
				>
					<div>
						<h2>入力内容をご確認の上、送信ボタンを押してください。</h2>
						<div className="modal-result-confirm">
							<span className="modal-title">お問い合わせの種類：</span>
							{this.state.r}
							<br />
							<span className="modal-title">氏名：</span>
							{this.state.seiKanji}
							{this.state.meiKanji} ({this.state.seiKana}
							{this.state.meiKana}) 様
							<br />
							<span className="modal-title">メールアドレス：</span>
							{this.state.email}
							<br />
							<span className="modal-title">電話番号：</span>
							{this.state.tel}
							<br />
							<span className="modal-title">貴社名：</span>
							{this.state.companyname}
							<br />
							<br />
							<span className="modal-title">お問い合わせの内容</span>
							<br />
							{this.state.message}
						</div>
						<button className="modal-button-cancel" onClick={this.handleCloseModal}>
							キャンセル
						</button>
						<button className="modal-button-submit" onClick={this.sendMail}>
							送信
						</button>
					</div>
				</Modal>
			</Layout>
		);
	}
}

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				excerpt
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					title
					description
				}
			}
		}
	}
`;
